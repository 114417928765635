import React, { lazy, Suspense, } from 'react';
import {
    Route,
    Switch,
} from 'react-router-dom';
import {
    Skeleton
} from "@reassure/ui/core";
import PlannedMaintenanceNotification from '../planned-maintenance-notification'
import {
    AppWrapper,
    AppContent,
    LoadingDots,
    Security,
} from './styles'
import { useApp, } from '../../store/app/hooks';
import { useMobileMenu } from '../../store/mobile-menu/hooks';
import { useStyles } from '../../store/styles/hooks';
import { useOktaConfig } from '../../services/okta/hooks';

const PageFooter = lazy(() => import('../page-footer'));
const MaintenanceRouter = lazy(() => import('./MaintenanceRouter'));
const AppRouter = lazy(() => import('./AppRouter'));
const PWARedirect = lazy(() => import('./pwa-redirect'));

const AppConfig = () => {

    const { maintenance } = useApp()
    const oktaConfig = useOktaConfig()
    const mobileMenuOpen = useMobileMenu()
    const { backgroundColor } = useStyles()

    return (

        <AppWrapper mobileMenuOpen={mobileMenuOpen} className="app">

            <AppContent backgroundColor={backgroundColor}>

                {!oktaConfig && !maintenance && <LoadingDots color="currentColor" />}

                <PlannedMaintenanceNotification />

                {maintenance && (
                    <Suspense fallback={<Skeleton />}>
                        <MaintenanceRouter />
                    </Suspense>
                )}

                {!maintenance && oktaConfig && (

                    <Security {...oktaConfig}>

                        <Suspense fallback={<LoadingDots color="currentColor" />}>

                            <Switch>

                                <Route path="/maintenance" component={MaintenanceRouter} />

                                <Route path="/pwa" component={PWARedirect} />

                                <Route path="*" component={AppRouter} />

                            </Switch>

                        </Suspense>

                    </Security>

                )}

            </AppContent>

            <Suspense fallback={<Skeleton />}>
                <PageFooter />
            </Suspense>

        </AppWrapper>
    )
}

export default AppConfig;